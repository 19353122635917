import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./SideNav.css";
import {
  MDBContainer,
  MDBSideNav,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavMenu,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBListGroup,
  MDBListGroupItem,
  MDBSwitch
} from "mdb-react-ui-kit";

import axios from "axios";

import { useUserPermissions } from "../../../context/UserPermissionsContext"

import { PiToggleLeftThin, PiToggleRightFill } from "react-icons/pi";

import madeByDencker from "../../../assets/Dencker-banner-light-transparent.png";

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA2hywDnWut6elombYMNKDnn08Jf1-6B9A",
  authDomain: "dencker-abb01.firebaseapp.com",
  projectId: "dencker-abb01",
  storageBucket: "dencker-abb01.appspot.com",
  messagingSenderId: "924075531945",
  appId: "1:924075531945:web:33927bad96ead4eb1b53b8",
  measurementId: "G-7JB32TXKNB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default function SideNav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFacultyModalOpen, setIsFacultyModalOpen] = useState(false);
  const [facultyNames, setFacultyNames] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState("");

  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);

  const homeButton = () => {
    const selectedFaculty = localStorage.getItem("selectedFaculty");
    window.location.href = `/Admin/${selectedFaculty}`;
  };

  function Logout() {
    localStorage.removeItem("firebaseUserId");
    localStorage.removeItem("selectedFaculty");
    window.location.href = "/Login";
  }

  // Function to handle the click on a faculty
  const handleFacultyClick = async (faculty) => {
    // Retrieve the firebaseUserId from localStorage
    const firebaseUserId = localStorage.getItem("firebaseUserId");

    if (!firebaseUserId) {
      console.error("No Firebase User ID found.");
      return;
    }

    try {
      // Make an HTTP POST request to your backend endpoint
      await axios.post("/api/set_active_faculty.php", {
        firebaseUserId,
        activeFaculty: faculty,
      });

      // Assuming the above request successfully updates the active faculty in the backend
      console.log("Active faculty updated successfully.");

      // Update the selected faculty in the local component state and localStorage
      setSelectedFaculty(faculty);
      localStorage.setItem("selectedFaculty", faculty);

      // Optionally, you might want to update the global context or perform other actions here

      // Redirect to the admin page for the selected faculty
      window.location.href = `/Admin/${faculty}`;
    } catch (error) {
      console.error("Error updating active faculty:", error);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken(true).then((idToken) => {
          axios
            .get("/api/get_facultySelect.php", {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            })
            .then((response) => {
              setFacultyNames([...new Set(response.data.faculties)]);
            })
            .catch((error) => {
              console.error("Error fetching faculties:", error);
            });
        });
      } else {
        alert("No user is signed in.");
        window.location.href = "/Login";
      }
    });
  }, []);

  const [theme, setTheme] = useState(() => {
    // Initialization of theme based on localStorage or default to 'light'
    return localStorage.getItem("theme") || "light";
  });

  // Update CSS file based on theme
  const switchCssFile = (theme) => {
    const container = document.body;
    if (theme === "dark") {
      container.classList.add("dark");
      container.classList.remove("light");
      import("../../../scss/mdb.dark.scss");
    } else {
      container.classList.add("light");
      container.classList.remove("dark");
      import("../../../scss/mdb.pro.scss");
    }
  };

  // Theme toggle handler
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    switchCssFile(newTheme);
  };

  useEffect(() => {
    // Apply the initial theme when component mounts
    switchCssFile(theme);
  }, []); // Run only once, when the component mounts

  // Define button text and icon based on the current theme
  const buttonIcon =
    theme === "light" ? (
      <PiToggleLeftThin onClick={toggleTheme} className="btn-toggle" />
    ) : (
      <PiToggleRightFill onClick={toggleTheme} className="btn-toggle" />
    );

  const handleClick = () => {
    document.body.classList.toggle("dark");
    window.location.reload();
  };

  // handle My profile link to send to correct user profile
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );

  useEffect(() => {
    validateUser();
  }, []);

  const validateUser = async () => {
    const firebaseUserId = localStorage.getItem("firebaseUserId");
    if (!firebaseUserId) {
      console.error("Firebase User ID not available.");
      clearLocalStorage();
      redirectToLogin();
      return;
    }

    try {
      const response = await axios.post(
        "../../../../api/fetch_user_data.php",
        {
          firebaseUserId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Add Authorization header if needed
          },
        }
      );

      const localStorageUsername = localStorage.getItem("username");
      if (response.data.user.username === localStorageUsername) {
        console.log("Validation success");
      } else {
        alert(
          "Validering av brukernavn feilet. Vi setter ny cookie med riktig brukernavn"
        );
        console.error("Validation failed. Usernames do not match.");
        localStorage.setItem("username", response.data.user.username);
        setUsername(response.data.user.username);
      }
    } catch (error) {
      console.error("Error during user validation:", error);
      clearLocalStorage();
      redirectToLogin();
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("firebaseUserId");
    localStorage.removeItem("username");
    localStorage.removeItem("selectedFaculty");
  };

  const redirectToLogin = () => {
    window.location.href = "/login";
  };

  // Handle permissions
  const { hasPermission } = useUserPermissions();

  return (
    <MDBContainer fluid>
      <div
        style={{ padding: "20px", zIndex: "999999" }}
        className="text-center position-fixed d-flex align-center bottom-0 sidenavToggle"
      >
        <MDBBtn floating size="lg" onClick={() => setIsOpen(!isOpen)} className="right-100" style={{cursor: 'pointer'}}>
          <MDBIcon fas icon="bars" size="2x" />
        </MDBBtn>
      </div>
      <MDBSideNav
        small
        open={isOpen}
        fixed
        getOpenState={(e: any) => setIsOpen(e)}
        bgColor={theme === "dark-mode" ? "dark" : undefined}
      >
        <MDBSideNavMenu>
          <a
            className="ripple d-flex justify-content-center py-4 mb-3 p-4 logo-wrapper"
            style={{ height: "120px" }}
            href="/"
            data-mdb-ripple-color="primary"
          >
            <img
              id="Dencker-logo"
              src={madeByDencker}
              alt="Dencker Logo"
              draggable="false"
              className="w-100"
            />
          </a>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} onClick={homeButton}>
              <MDBIcon icon="home" className="fa-fw me-3" />
              <span className="sidenav-non-slim">Hjem</span>
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem className="">
            <MDBSideNavLink tag={Link} onClick={toggleOpen}>
              <MDBIcon far icon="building" size="1x" className="fa-fw me-3" />
              <span className="sidenav-non-slim">Endre lokasjon</span>
            </MDBSideNavLink>
          </MDBSideNavItem>
          {hasPermission("FacultyConfigure") && (
            <MDBSideNavItem className="pt-3">
              <span className="sidenav-subheading text-muted">Portal</span>
              <MDBSideNavLink tag={Link} to="/Configure">
                <MDBIcon fas icon="cog" className="fa-fw me-3" />
                <span className="sidenav-non-slim">Avansert konfigurering</span>
              </MDBSideNavLink>
            </MDBSideNavItem>
          )}
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} to="/ModalPanel">
              <MDBIcon fas icon="file-signature" className="fa-fw me-3" />
              Brukerveiledninger
            </MDBSideNavLink>
          </MDBSideNavItem>
          {hasPermission("FacultyCreate") && (
            <MDBSideNavItem>
              <MDBSideNavLink tag={Link} to="/FacultyRegistration">
                <MDBIcon fas icon="plus" className="fa-fw me-3" />
                Opprett/Slett lokasjoner
              </MDBSideNavLink>
            </MDBSideNavItem>
          )}
          {hasPermission("UserView") && (
            <div className="user-wrapper">
              <MDBSideNavItem className="pt-3">
                <span className="sidenav-subheading text-muted">Brukere</span>
                <MDBSideNavLink tag={Link} to="/Register">
                  <MDBIcon fas icon="user-plus" className="fa-fw me-3" />
                  <span className="sidenav-non-slim">Registrer brukere</span>
                </MDBSideNavLink>
              </MDBSideNavItem>

              <MDBSideNavItem>
                <MDBSideNavLink tag={Link} to="/userList">
                  <MDBIcon fas icon="user-edit" className="fa-fw me-3" />
                  <span className="sidenav-non-slim">Endre brukere</span>
                </MDBSideNavLink>
              </MDBSideNavItem>
            </div>
          )}
          <MDBSideNavItem className="pt-3">
            <span className="sidenav-subheading text-muted">Brukerkonto</span>
            <MDBSideNavLink tag={Link} to={`/profile/${username}`}>
              <MDBIcon fas icon="user-circle" className="fa-fw me-3" />
              <span className="sidenav-non-slim">Min profil</span>
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink tag={Link} onClick={Logout}>
              <MDBIcon fas icon="file-contract" className="fa-fw me-3" />
              <span className="sidenav-non-slim">Logg ut</span>
            </MDBSideNavLink>
          </MDBSideNavItem>
          {hasPermission("Developer") && (
            <div className="user-wrapper">
              <MDBSideNavItem className="pt-3">
              <span className="sidenav-subheading text-muted">Developer</span>
                <MDBSideNavLink tag={Link} to="/Changelog">
                  <MDBIcon fas icon="user-plus" className="fa-fw me-3" />
                  <span className="sidenav-non-slim">Create changelog</span>
                </MDBSideNavLink>
              </MDBSideNavItem>
            </div>
          )}

          {/* Add new Stripe component links */}

          {/* <MDBSideNavItem className="pt-3">
            <span className="sidenav-subheading text-muted">Betaling</span>
            <MDBSideNavLink tag={Link} to="/checkout">
              <MDBIcon fas icon="credit-card" className="fa-fw me-3" />
              <span className="sidenav-non-slim">Kjøp abonnement</span>
            </MDBSideNavLink>
            <MDBSideNavLink tag={Link} to="/portal">
              <MDBIcon fas icon="money-bill-wave" className="fa-fw me-3" />
              <span className="sidenav-non-slim">Administrer abonnement</span>
            </MDBSideNavLink>
          </MDBSideNavItem> */}

          <MDBSideNavItem className="pt-3 d-flex flex-column">
            <span className="sidenav-subheading text-muted">Bytt tema</span>
          </MDBSideNavItem>
          <MDBSideNavItem className="ms-4 my-2">
            <MDBSwitch
              id="themeSwitch"
              label={theme === "light" ? "Light Mode" : "Dark Mode"}
              checked={theme === "dark"}
              onChange={toggleTheme}
            />
          </MDBSideNavItem>
        </MDBSideNavMenu>
      </MDBSideNav>
      <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Dine tilganger:</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBListGroup style={{ minWidth: "22rem" }} light>
                {facultyNames.map((faculty) => (
                  <MDBListGroupItem
                    tag="button"
                    action
                    noBorders
                    active={selectedFaculty === faculty} // Set active to true when selectedFaculty matches faculty
                    key={faculty}
                    aria-current={
                      selectedFaculty === faculty ? "true" : "false"
                    }
                    type="button"
                    className="px-3 mt-2 mb-2"
                    onClick={() => handleFacultyClick(faculty)}
                  >
                    {faculty}
                  </MDBListGroupItem>
                ))}
              </MDBListGroup>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen}>
                Lukk
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  );
}
