import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Navbar from "../navbar/Navbar";
import NoAccess from "../../noAccess/NoAccess"; // If no permission to view component, display NoAccess component

import {
  MDBStepper,
  MDBStepperStep,
  MDBInput,
  MDBIcon,
  MDBSelect,
  MDBBtn,
  MDBStepperForm,
  MDBContainer,
  MDBTypography,
  MDBValidationItem,
  MDBValidation,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBListGroup,
  MDBListGroupItem,
  MDBRadio,
  MDBBtnGroup,
} from "mdb-react-ui-kit";

import SuccessAlert from "../../components/successAlert/SuccessAlert";
import FailAlert from "../../components/failAlert/FailAlert";

const Register = () => {
  const [theme, setTheme] = useState();

    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

  // Role modal
    const [roleModal, setRoleModal] = useState(false);

    const toggleRoleModal = () => setRoleModal(!roleModal);

  // MAKE RESPONSIVE STEPPER GREAT AGAIN - make it response
  const [mode, setMode] = useState("horizontal");

  const handleResize = useCallback(() => {
    const width = window.innerWidth;

    if (width > 768) {
      setMode("horizontal");
    } else if (width <= 768 && width > 400) {
      setMode("vertical");
    }
  }, []);

  useEffect(() => {
    // Perform a handleResize on mount to set the initial mode
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  

  // Form scripts


  useEffect(() => {
    axios
      .get("/api/get_roles.php")
      .then((response) => {
        const userRoleData = JSON.parse(localStorage.getItem("roleData"));
        const userRoleLevel = userRoleData;

        // Filter roles based on level
        const filteredRoles = response.data.filter(
          (role) => role.level <= userRoleLevel
        );
        setRoles(filteredRoles);
      })
      .catch((error) => {
        console.error("Failed to fetch roles:", error);
      });
  }, []);

  // Define data
  const [roles, setRoles] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [userData, setUserData] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedFaculties, setSelectedFaculties] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRolesByFaculty, setSelectedRolesByFaculty] = useState({});

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailValueChange = (e) => {
    setEmailValue(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

const handleRoleChange = (facultyId, selectedRoleId) => {
  setSelectedRolesByFaculty((prevState) => ({
    ...prevState,
    [facultyId]: selectedRoleId,
  }));
  setSelectedRole(selectedRoleId); // Update the selected role
};


    const handleFacultySelection = (selectedItems) => {
    if (selectedItems && Array.isArray(selectedItems)) {
      const facultyIds = selectedItems.map((item) => item.value);
      setSelectedFaculties(facultyIds);
    } else {
      // Handle the case where selectedItems is not what you expect
      console.error("Unexpected format for selectedItems:", selectedItems);
    }
  };

const handleSubmit = async (event) => {
  event.preventDefault();

  const userData = {
    FirstName: firstName,
    LastName: lastName,
    Email: emailValue,
    Username: emailValue,
    Password: password,
    Faculties: selectedFaculties,
    Roles: selectedRolesByFaculty,
    SelectedRole: selectedRole, // Add the selected role to userData
  };

  try {
    const response = await axios.post("/api/register.php", userData);
    if (response.data.success) {
          setTimeout(() => {
            setIsSuccess(true); // Set success state to true
          }, 5000);
    } else {
          setTimeout(() => {
            setIsFailed(true); // Set success state to true
          }, 5000);
    }
  } catch (error) {
    console.error("There was an error submitting the form:", error);
    alert("Registrering feilet");
  }
};



  
  //Fetch data to populate fields
  useEffect(() => {
    axios
      .get("/api/get_faculties.php") // Adjust the path as necessary
      .then((response) => {
        setFaculties(response.data); // Assuming the data is the array of faculties
      })
      .catch((error) => {
        console.error("There was an error fetching the faculties!", error);
      });
  }, []); // Empty array ensures this effect runs only once after the component mounts

  useEffect(() => {
    axios
      .get("/api/get_roles.php")
      .then((response) => {
        setRoles(response.data); // Make sure this is correct
      })
      .catch((error) => {
        console.error("Failed to fetch roles:", error);
      });
  }, []);

  const roleOptions = roles.map((role) => ({
    text: role.name,
    value: role.id,
  }));

  const facultyOptions = faculties
    ? faculties.map((faculty) => ({
        text: faculty.name,
        value: faculty.id,
      }))
    : [];

  

    // Handle buttons for changing step
      const prevRef = useRef(null);
      const nextRef = useRef(null);



  return (
    <MDBContainer fluid>
      <Navbar />
      <MDBContainer className="my-4">
        {isSuccess && <SuccessAlert />}
        {isFailed && <FailAlert />}
        <MDBTypography tag="h4">Registrer bruker</MDBTypography>
      </MDBContainer>
      <MDBContainer className="h-100">
        <div className="w-100">
          <div className="mb-3">
            <MDBBtn className="mx-2" ref={prevRef}>
              Tilbake
            </MDBBtn>
            <MDBBtn className="mx-2" ref={nextRef}>
              Neste
            </MDBBtn>
          </div>
        </div>
        <MDBStepper
          type={mode}
          externalNext={nextRef}
          externalPrev={prevRef}
          style={{ height: "auto", overflow: "visible" }}
          linear
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          enctype="multipart/form-data"
          preventDefault={true}
        >
          <MDBStepperForm onSubmit={handleSubmit}>
            <MDBStepperStep
              headIcon={<MDBIcon fas icon="globe" />}
              headText="Navn"
              itemId={1}
              customValidation={(input: HTMLInputElement) => {
                // Check if the input ID is 'password'
                if (input.id === "password") {
                  const password = input.value;
                  // Regular expression for password policy
                  const passwordPolicyRegex =
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

                  // Test the password against the regex
                  const isValid = passwordPolicyRegex.test(password);

                  if (!isValid) {
                    // Set a custom validity message if the password doesn't meet the policy
                    input.setCustomValidity(
                      "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, and one number."
                    );
                  } else {
                    // Clear any custom validity message if the password meets the policy
                    input.setCustomValidity("");
                  }

                  return isValid;
                }

                if (input.id === "email") {
                  const emailRegex =
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                  if (emailRegex.test(input.value)) {
                    input.setCustomValidity("");
                  } else {
                    input.setCustomValidity("Invalid");
                  }

                  return emailRegex.test(input.value);
                }

                // Check if the input ID is 'password'
                if (input.id === "lastName") {
                  const inputLength = input.value.length;

                  if (inputLength < 2) {
                    input.setCustomValidity("Invalid");
                  } else {
                    input.setCustomValidity("");
                  }

                  return inputLength >= 2;
                }

                // Check if the input ID is 'password'
                if (input.id === "firstName") {
                  const inputLength = input.value.length;

                  if (inputLength < 2) {
                    input.setCustomValidity("Invalid");
                  } else {
                    input.setCustomValidity("");
                  }

                  return inputLength >= 2;
                }

                // If the input is not the one with ID 'password', 'lastName' or 'email', no custom validation is applied
                return true;
              }}
            >
              <MDBContainer className="pb-3">
                <MDBValidationItem
                  invalid
                  feedback="Fyll inn jobb epost til ansatte"
                  className="mt-4"
                >
                  <MDBInput
                    label="E-post"
                    type="email"
                    required
                    value={emailValue || ""}
                    onChange={handleEmailValueChange}
                    id="email"
                    name="Email"
                    placeholder="brukernavn@domene.no"
                    autoComplete="disabled"
                  />
                </MDBValidationItem>
              </MDBContainer>
              <MDBContainer className="pb-3">
                <MDBValidationItem
                  invalid
                  feedback="Fylles inn når epost er fylt ut"
                  className="mt-4"
                >
                  <MDBInput
                    label="Samme som e-post"
                    value={emailValue}
                    onChange={handleUsernameChange}
                    type="email"
                    id="username"
                    name="username"
                    required
                    disabled="true" // disabled the input field
                    style={{ color: "#d9d9d9" }}
                  />
                </MDBValidationItem>
              </MDBContainer>
              <MDBContainer className="pb-3">
                <MDBValidation isValidated>
                  <MDBValidationItem
                    invalid
                    feedback="Passord må inneholde minst: 8 tegn, tall, små og store bokstaver."
                    className="mt-4"
                  >
                    <MDBInput
                      label="Passord"
                      type="password"
                      id="password"
                      name="password"
                      value={password || ""}
                      required
                      onChange={handlePasswordChange}
                      autoComplete="disabled"
                    />
                  </MDBValidationItem>
                </MDBValidation>
              </MDBContainer>
              <MDBContainer className="pb-3">
                <MDBValidationItem
                  invalid
                  feedback="Fyll inn et gyldig fornavn"
                  className="mt-4"
                >
                  <MDBInput
                    label="Fornavn"
                    type="text"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    required
                    id="firstName"
                    name="FirstName"
                    placeholder="Ola"
                    autoComplete="disabled"
                  />
                </MDBValidationItem>
              </MDBContainer>
              <MDBContainer className="pb-3">
                <MDBValidationItem
                  invalid
                  feedback="Fyll inn et gydlig etternavn"
                  className="mt-4"
                >
                  <MDBInput
                    label="Etternavn"
                    type="text"
                    value={lastName}
                    onChange={handleLastNameChange}
                    id="lastName"
                    name="LastName"
                    required
                    placeholder="Nordmann"
                  />
                </MDBValidationItem>
              </MDBContainer>
            </MDBStepperStep>

            <MDBStepperStep
              headIcon={<MDBIcon fas icon="shield-alt" />}
              headText="Tilganger"
              itemId={2}
            >
              <MDBContainer fluid className="p-1">
                <MDBCard className="w-50 p-4 m-1 d-flex flex-row">
                  <div className="w-25 d-flex align-items-center justify-content-center">
                    <MDBIcon
                      className="m-auto p-4"
                      fas
                      icon="map-marker-alt"
                      color="primary"
                      size="4x"
                    />
                  </div>
                  <MDBCardBody>
                    <MDBCardTitle>Velg tilhørighet</MDBCardTitle>
                    <MDBCardText>
                      Velg hvilke lokasjoner vedkommende skal ha tilgang til
                    </MDBCardText>
                    <MDBSelect
                      multiple
                      search
                      data={facultyOptions}
                      label="Velg en fakultet"
                      value={selectedFaculties}
                      onValueChange={handleFacultySelection}
                    />
                  </MDBCardBody>
                </MDBCard>
              </MDBContainer>

              <MDBContainer
                fluid
                className="container-fluid p-1 flex-row d-flex"
              >
                <MDBRow className="w-100">
                  {selectedFaculties.map((facultyId) => {
                    const faculty = faculties.find((f) => f.id === facultyId);
                    if (!faculty) {
                      return null;
                    }

                    return (
                      <MDBCol key={facultyId} size="6">
                        <MDBCard className="w-100 p-4 m-1 d-flex flex-row">
                          <div className="w-25 d-flex align-items-center justify-content-center">
                            <MDBIcon
                              className="m-auto p-4"
                              fas
                              icon="sitemap"
                              color="primary"
                              size="4x"
                            />
                          </div>
                          <MDBCardBody>
                            <MDBCardTitle>
                              Velg rolle for{" "}
                              {faculty ? faculty.name : "Faculty"}
                            </MDBCardTitle>
                            <MDBCardText>
                              Velg en rolle for vedkommende. <br />
                              <MDBBtn
                                color="primary"
                                className="my-4"
                                size="sm"
                                onClick={toggleRoleModal}
                                type="button"
                              >
                                Trykk her
                              </MDBBtn>{" "}
                              <br />
                              for å se rolle tilganger.
                            </MDBCardText>
                            <MDBContainer className="pb-3">
                              <MDBBtnGroup className="d-inline">
                                {roles.map((role) => (
                                  <MDBRadio
                                    key={role.id}
                                    btnColor="secondary"
                                    labelClass="m-1"
                                    id={`btn-radio-${role.id}-${facultyId}`} // Unique ID for each button
                                    name={`role-options-${facultyId}`} // Unique name for each group
                                    wrapperTag="span"
                                    label={role.name}
                                    checked={
                                      selectedRolesByFaculty[facultyId] ===
                                      role.id
                                    }
                                    onChange={() =>
                                      handleRoleChange(facultyId, role.id)
                                    }
                                  />
                                ))}
                              </MDBBtnGroup>
                            </MDBContainer>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    );
                  })}
                </MDBRow>
              </MDBContainer>

              <MDBModal open={roleModal} backdrop={false} setOpen={setRoleModal} tabIndex="-1">
                <MDBModalDialog scrollable size="xl">
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>Rolle tilganger</MDBModalTitle>
                      <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleRoleModal}
                      ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                      <MDBContainer>
                        {roles.map((role) => (
                          <MDBContainer key={role.id}>
                            <MDBTypography tag="p">{role.name}</MDBTypography>
                            {role.permissions && role.permissions.length > 0 ? (
                              <MDBListGroup
                                className="d-flex flex-wrap my-4"
                                style={{ marginBottom: "1rem" }}
                              >
                                {role.permissions.map((permission, index) => (
                                  <MDBListGroupItem
                                    className="w-100"
                                    key={index}
                                  >
                                    <p className="my-2">{permission}</p>
                                  </MDBListGroupItem>
                                ))}
                              </MDBListGroup>
                            ) : (
                              <p className="lead">Ingen tilganger</p>
                            )}
                          </MDBContainer>
                        ))}
                      </MDBContainer>
                    </MDBModalBody>

                    <MDBModalFooter>
                      <MDBBtn color="secondary" onClick={toggleRoleModal}>
                        Lukk
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            </MDBStepperStep>
            <MDBStepperStep
              headIcon={<MDBIcon fas icon="shield-alt" />}
              headText="Fullfør"
              itemId={3}
            >
              {/* Display form data */}
              <MDBContainer className="mt-4">
                <MDBTypography tag="h5">Sammendrag:</MDBTypography>
                <MDBListGroup>
                  <MDBListGroupItem><strong>Fornavn:</strong> {firstName}</MDBListGroupItem>
                  <MDBListGroupItem><strong>Etternavn:</strong> {lastName}</MDBListGroupItem>
                  <MDBListGroupItem><strong>E-post:</strong> {emailValue}</MDBListGroupItem>
                  <MDBListGroupItem><strong>Brukernavn:</strong> {emailValue}</MDBListGroupItem>
                  <MDBListGroupItem><strong>Passord:</strong> ******</MDBListGroupItem>
                  <MDBListGroupItem><strong>Valgt Fakultet:</strong>  <h3>Valgte fakulteter:</h3>

                      {selectedFaculties.map((facultyId) => {
                        const faculty = faculties.find((f) => f.id === facultyId);
                        return (
                          <strong key={facultyId}>
                            {faculty ? faculty.name : "Ukjent fakultet"} {/* Viser fakultetsnavnet */}
                          </strong>
                        );
                      })}
                      </MDBListGroupItem>
                  <MDBListGroupItem><strong>Valgt Rolle:</strong> {selectedRole}</MDBListGroupItem>
                </MDBListGroup>
              </MDBContainer>

              <MDBBtn color="success" type="submit" className="my-4" onClick={handleSubmit}>
                Registrer
              </MDBBtn>
            </MDBStepperStep>

          </MDBStepperForm>
        </MDBStepper>
      </MDBContainer>
    </MDBContainer>
  );
};

export default Register;
