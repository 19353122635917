import axios from "axios";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import Typewriter from 'typewriter-effect'
import Navbar from "../navbar/Navbar";
import {
  MDBBtn,
  MDBInput,
  MDBContainer,
  MDBIcon,
  MDBAnimation,
  MDBSpinner,
  MDBRow,
  MDBCol,
  MDBProgress,
  MDBProgressBar,
  MDBTextArea,
  MDBRange,
  MDBCheckbox,
  MDBAlert,
  MDBTypography,
  MDBFile,
} from "mdb-react-ui-kit";
import AdminModal from "../adminModal/AdminModal";

import Logo from "../../../assets/favicon.png";

import "./ProfilePage.css";
import Loader from "../../components/loader/Loader";


// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA2hywDnWut6elombYMNKDnn08Jf1-6B9A",
  authDomain: "dencker-abb01.firebaseapp.com",
  projectId: "dencker-abb01",
  storageBucket: "dencker-abb01.appspot.com",
  messagingSenderId: "924075531945",
  appId: "1:924075531945:web:33927bad96ead4eb1b53b8",
  measurementId: "G-7JB32TXKNB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


function ProfilePage() {
    const [userData, setUserData] = useState(null);
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [modals, setModals] = useState([]);
    const [checked, setChecked] = useState(true);
    const [theme, setTheme] = useState("light-mode");
    const [isEditMode, setIsEditMode] = useState(false);
    const [social1, setSocial1] = useState("");
    const [social2, setSocial2] = useState("");
    const [skill1, setSkill1] = useState("");
    const [skill2, setSkill2] = useState("");
    const [skill3, setSkill3] = useState("");
    const [description, setDescription] = useState("");
    const [skill1Progress, setSkill1Progress] = useState(0);
    const [skill2Progress, setSkill2Progress] = useState(0);
    const [skill3Progress, setSkill3Progress] = useState(0);
    const [showGuides, setShowGuides] = useState(false);
    const [profilePicture, setProfilePicture] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [profilePictureFile, setProfilePictureFile] = useState(null);
    const [backgroundImageFile, setBackgroundImageFile] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [typewriterFinished, setTypewriterFinished] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const history = useHistory();

    const handleTypewriterInit = (typewriter) => {
      if (userData) {
        typewriter
          .pauseFor(500)
          .typeString(`${userData.firstName} ${userData.lastName}`)
          .callFunction(() => {
            setTypewriterFinished(true); // Set to true when typing is done
          })
          .start();
      }
    };

    const getFacultyNameFromPath = () => {
      const path = window.location.pathname;
      const pathParts = path.split("/");
      return pathParts.length > 1 ? pathParts[pathParts.length - 1] : null;
    };

    const usernameFromURL = getFacultyNameFromPath();

    useEffect(() => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdToken(true).then((token) => {
            fetchData(usernameFromURL, token, user.email);
          });
        } else {
          fetchData(usernameFromURL);
        }
      });
    }, [usernameFromURL]);

    const fetchData = async (usernameFromURL, token = null) => {
      try {
        const headers = token ? { Authorization: `Bearer ${token}` } : {};

        const profileResponse = await axios.get(
          `https://portal.dencker.no/api/get_user_profile.php?username=${usernameFromURL}`,
          { headers }
        );

        if (profileResponse.data.success) {
          setUserData(profileResponse.data.userProfile);
          setIsOwner(profileResponse.data.isOwner);

          setShowGuides(profileResponse.data.userProfile.showguides === 1);

          const profilePictureUrl = profileResponse.data.userProfile.profilepicture || `https://portal.dencker.no/api/profiles/${usernameFromURL}/profile_img/default_profile_image.jpeg`;
          const backgroundImageUrl = profileResponse.data.userProfile.backgroundimage || `https://portal.dencker.no/api/profiles/${usernameFromURL}/profile_background/default_profile_background.jpeg`;

          setProfilePicture(profilePictureUrl);
          setBackgroundImage(backgroundImageUrl);
        } else {
          console.error("Failed to fetch user profile:", profileResponse.data.message);
        }

        const modalsResponse = await axios.get(
          `https://portal.dencker.no/api/get_user_modal.php?username=${usernameFromURL}`,
          { headers }
        );

        if (modalsResponse.data.success) {
          const fetchedModals = modalsResponse.data.modals;
          const uniqueModalsMap = new Map();
          fetchedModals.forEach((modal) => {
            if (!uniqueModalsMap.has(modal.modalName)) {
              uniqueModalsMap.set(modal.modalName, modal);
            }
          });
          const uniqueModals = Array.from(uniqueModalsMap.values());
          setModals(uniqueModals);
        } else {
          console.error("Failed to fetch user modals:", modalsResponse.data.message);
        }
      } catch (error) {
        console.error("Error during fetch operation:", error);
      } finally {
        setLoading(false);
      }
    };

    const handleEditClick = () => {
      setIsEditMode((prevMode) => !prevMode);
    };

    const handleDescriptionChange = (event) => {
      setDescription(event.target.value);
    };

    const handleSkill1Change = (event) => {
      setSkill1(event.target.value);
    };

    const handleSkill2Change = (event) => {
      setSkill2(event.target.value);
    };

    const handleSkill3Change = (event) => {
      setSkill3(event.target.value);
    };

    const handleSkill1ProgressChange = (event) => {
      setSkill1Progress(event.target.value);
    };

    const handleSkill2ProgressChange = (event) => {
      setSkill2Progress(event.target.value);
    };

    const handleSkill3ProgressChange = (event) => {
      setSkill3Progress(event.target.value);
    };

    const handleSocial1Change = (event) => {
      setSocial1(event.target.value);
    };

    const handleSocial2Change = (event) => {
      setSocial2(event.target.value);
    };

    const handleProfilePictureFileChange = (event) => {
      if (event.target.files[0]) {
        setProfilePictureFile(event.target.files[0]);
        setProfilePicture(URL.createObjectURL(event.target.files[0]));
      }
    };

    const handleBackgroundImageFileChange = (event) => {
      if (event.target.files[0]) {
        setBackgroundImageFile(event.target.files[0]);
        setBackgroundImage(URL.createObjectURL(event.target.files[0]));
      }
    };

  const handleCheckboxChange = (event) => {
    const newCheckedState = event.target.checked;
    setShowGuides(newCheckedState); // Use boolean here
    setUserData((prevUserData) => ({
      ...prevUserData,
      showguides: newCheckedState ? 1 : 0,
    }));
  };

    const updateProfile = async () => {
      const formData = new FormData();
      if (description) formData.append("description", description);
      if (skill1) formData.append("skill1", skill1);
      if (skill2) formData.append("skill2", skill2);
      if (skill3) formData.append("skill3", skill3);
      if (skill1Progress) formData.append("skill1Progress", skill1Progress);
      if (skill2Progress) formData.append("skill2Progress", skill2Progress);
      if (skill3Progress) formData.append("skill3Progress", skill3Progress);
      formData.append("showguides", showGuides ? 1 : 0);
      if (social1) formData.append("social1", social1);
      if (social2) formData.append("social2", social2);

      if (profilePictureFile instanceof File) {
        formData.append("profilepicture", profilePictureFile);
      }

      if (backgroundImageFile instanceof File) {
        formData.append("backgroundImage", backgroundImageFile);
      }

      const user = getAuth().currentUser;
      if (user) {
        try {
          const token = await user.getIdToken(true);
          const response = await axios({
            method: "post",
            url: "https://portal.dencker.no/api/update_user_profile.php",
            data: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log("Profile updated successfully:", response.data);
          setTimeout(() => {
            window.location.reload()
          }, 3000);
        } catch (error) {
          console.error("Error updating profile:", error);
        }
      } else {
        console.error("No user logged in");
      }
    };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim() === "") return;

    try {
      const response = await axios.get(`https://portal.dencker.no/api/search_users.php?query=${searchQuery}`);
      if (response.data.success) {
        setSearchResults(response.data.users);
      } else {
        setSearchResults([]);
        setSearchQuery("");
      }
    } catch (error) {
      console.error("Error searching for users:", error);
      setSearchResults([]);
      setSearchQuery("");
    }
  };

  const handleUserClick = (username) => {
    setSearchResults([]); // Clear the search results
    history.push(`/profile/${username}`);
  };
    if (loading) {
      return <Loader />;
    }




  return (
    <div className="comp_wrapper">
      <div className="d-flex flex-row">
        <img src={Logo} alt="Logo" className="mx-2 brand" style={{height:"100px"}} />
      <div className="search_wrapper p-4 w-75 d-flex flex-column align-items-center">
        <div className="position-absolute w-50" style={{zIndex:"9999"}}>
        <form className="d-flex input-group" onSubmit={handleSearch}>
          <input
            type="search"
            className="form-control"
            placeholder="Søk etter brukere"
            aria-label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <MDBBtn color="primary" type="submit">Søk</MDBBtn>
        </form>

        {searchResults.length > 0 && (
          <div className="search-results bg-primary bg-opacity-25 w-100">
            {searchResults.map((user) => (
              <div
                key={user.username}
                onClick={() => handleUserClick(user.username)}
                className="search-result-item p-2"
              >
                {user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.username}
              </div>
            ))}
          </div>
        )}
        </div>
      </div>
        <div className="my-auto d-flex h-100 flex-column mx-3">
        {isOwner && (
          <MDBBtn
            onClick={handleEditClick}
            color="primary"
            className="m-auto p-2 w-100 d-flex justify-content-center"
          >
            Endre bruker <MDBIcon far icon="edit" size="xl" className="mx-2 my-auto" floating />
          </MDBBtn>
        )}
        </div>
      </div>
      {!isEditMode ? (
        <div className="visually-hidden"></div>
      ) : (
        <MDBBtn
          rounded
          size="lg"
          color="success"
          className="position-fixed start-50"
          style={{ top: "95%", zIndex: "99999", width: '90px' }}
          onClick={updateProfile}
        >
          lagre endringer
        </MDBBtn>
      )}
      <MDBContainer fluid className="profile_header">
        <div
          className="bg-image mb-3 rounded-bottom shadow-1-strong"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            height: "400px",
          }}
        >
          <div
            className="mask"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
          >

            <Navbar />
            <div className="d-flex w-50 m-auto justify-content-center text-center flex-column align-items-center p-3">
              <MDBAnimation
                start="onLoad"
                duration={600}
                animation="fade-in-down"
              >
                <h4 className="text-light">Hei, jeg er,</h4>
              </MDBAnimation>
              <MDBAnimation
                start="onLoad"
                duration={600}
                delay={500}
                animation="fade-in"
              >
                {!typewriterFinished ? (
                  <h2
                    className="text-light"
                    style={{ fontWeight: "900 important" }}
                  >
                    <Typewriter
                      onInit={handleTypewriterInit}
                      options={{
                        autoStart: true,
                        loop: false,
                      }}
                    />
                  </h2>
                ) : (
                  <h2
                    className="text-light"
                    style={{ fontWeight: "900 important" }}
                  >
                    {userData.firstName} {userData.lastName}.
                  </h2>
                )}
              </MDBAnimation>
              <MDBAnimation
                start="onLoad"
                duration={600}
                delay={1000}
                animation="fade-in"
              ></MDBAnimation>
            </div>
          </div>
        </div>
        <MDBAnimation
          animation="zoom-in"
          delay={1500}
          duration={600}
          start="onLoad"
        >
          {!isEditMode ? (
            <div className="d-flex justify-content-center">
              <img
                src={profilePicture}
                className="rounded-circle shadow-3-strong position-absolute"
                alt="User profile"
                style={{
                  width: "168px",
                  height: "168px",
                  marginTop: "-120px",
                }}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <img
                src={
                  userData.profilepicture || (
                    <MDBSpinner grow>
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  )
                }
                className="rounded-circle shadow-3-strong position-absolute"
                alt=""
                style={{
                  width: "168px",
                  height: "168px",
                  marginTop: "-120px",
                }}
              />
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex justify-content-center">
                  <img
                    src={
                      profilePicture || (
                        <MDBSpinner grow>
                          {" "}
                          <span className="visually-hidden">
                            Loading...
                          </span>{" "}
                        </MDBSpinner>
                      )
                    }
                    className="rounded-circle shadow-3-strong position-absolute"
                    alt=""
                    style={{
                      width: "168px",
                      height: "168px",
                      marginTop: "-120px",
                    }}
                  />
                  <div className="d-flex mt-5 flex-column justify-content-center" id="MDBFileUpload">
                    <MDBFile
                      label="Velg et profil bilde"
                      id="profileImg"
                      onChange={handleProfilePictureFileChange}
                    />
                    <MDBFile
                      className="mt-3"
                      label="Velg et bakgrunnsbilde"
                      id="backgroundImg"
                      onChange={handleBackgroundImageFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </MDBAnimation>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol md="3">
            <div className="mt-4 p-5 d-flex justify-content-left flex-column align-items-center">
              <div className="d-flex flex-row w-100">
                <div className="d-flex flex-column w-100">
                  <h5>Mine ferdigheter</h5>
                  <div className="progress_wrapper w-100 p-4">
                    {!isEditMode ? (
                      <div className="div">
                        <h5>{userData.skill1 || "Laster ..."}</h5>
                        <MDBProgress>
                          <MDBProgressBar
                            width={userData.skill1progress}
                            valuemin={0}
                            valuemax={100}
                          />
                        </MDBProgress>
                      </div>
                    ) : (
                      <div className="">
                        <MDBInput
                          label={userData.skill1}
                          placeholder={userData.skill1}
                          className={
                            theme === "light" ? "text-dark lead p-4" : " lead"
                          }
                          onChange={handleSkill1Change}
                        />
                        <MDBRange
                          step="5"
                          defaultValue={userData.skill1progress}
                          id={userData.skill1progress}
                          label="Ferdighet 1"
                          onChange={handleSkill1ProgressChange}
                        />
                      </div>
                    )}
                  </div>
                  <div className="progress_wrapper w-100 p-4">
                    {!isEditMode ? (
                      <div className="div">
                        <h5>{userData.skill2 || "Laster ..."}</h5>
                        <MDBProgress>
                          <MDBProgressBar
                            width={userData.skill2progress}
                            valuemin={0}
                            valuemax={100}
                          />
                        </MDBProgress>
                      </div>
                    ) : (
                      <div className="">
                        <MDBInput
                          label={userData.skill2}
                          placeholder={userData.skill2}
                          className={
                            theme === "light" ? "text-dark lead p-4" : " lead"
                          }
                          onChange={handleSkill2Change}
                        />
                        <MDBRange
                          step="5"
                          defaultValue={userData.skill2progress}
                          id={userData.skill2progress}
                          label="Ferdighet 2"
                          onChange={handleSkill2ProgressChange}
                        />
                      </div>
                    )}
                  </div>
                  <div className="progress_wrapper w-100 p-4">
                    {!isEditMode ? (
                      <div className="div">
                        <h5>{userData.skill3 || "Laster ..."}</h5>
                        <MDBProgress>
                          <MDBProgressBar
                            width={userData.skill3progress}
                            valuemin={0}
                            valuemax={100}
                          />
                        </MDBProgress>
                      </div>
                    ) : (
                      <div className="">
                        <MDBInput
                          label={userData.skill3}
                          placeholder={userData.skill3}
                          className={
                            theme === "light" ? "text-dark lead p-4" : " lead"
                          }
                          onChange={handleSkill3Change}
                        />
                        <MDBRange
                          step="5"
                          defaultValue={userData.skill3progress}
                          id={userData.skill3progress}
                          label="Ferdighet 3"
                          onChange={handleSkill3ProgressChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <hr className="vr vr-blurry" />
              </div>
            </div>
          </MDBCol>
          <MDBCol md="8">
            <div className="mt-4 p-5 d-flex flex-column align-items-left">
              <h4 className="p-2 d-flex">Biografi</h4>
              {!isEditMode ? (
                <p id="editable" class="lead p-2 w-100" style={{wordBreak:"break-word"}}>
                  {userData.description}
                </p>
              ) : (
                <MDBTextArea
                  className={
                    theme === "light"
                      ? "text-dark lead p-4 w-100 biografi"
                      : " lead"
                  }
                  color="dark"
                  type="text"
                  style={{ fontSize: "1.25rem" }}
                  placeholder={userData.description}
                  defaultValue={userData.description}
                  onChange={handleDescriptionChange}
                  rows={6}
                />
              )}
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBAnimation
            start="onLoad"
            duration={600}
            delay={1500}
            animation="fade-in-up"
          >
            <div className="socials d-flex p-5 flex-column">
              <MDBTypography tag="h5">Kontakt meg her:</MDBTypography>
              <div className="socials-left d-flex">
                {!isEditMode ? (
                  <div className="socials-1 flex-row align-items-center d-flex">
                    <MDBBtn
                      size="lg"
                      floating
                      style={{ backgroundColor: "#ac2bac" }}
                      href="#"
                      className="m-2"
                    >
                      <MDBIcon className="rounded" fab icon="linkedin-in" />
                    </MDBBtn>
                    <p
                      className={theme === "light" ? "text-dark" : "my-auto"}
                    >
                      {userData.social1}
                    </p>
                  </div>
                ) : (
                  <div className="socials-1 flex-row align-items-center d-flex">
                    <MDBBtn
                      size="lg"
                      floating
                      style={{
                        backgroundColor: "#ac2bac",
                        height: "45px",
                        width: "59px",
                      }}
                      href="#"
                      className="m-2"
                    >
                      <MDBIcon className="rounded" fab icon="linkedin-in" />
                    </MDBBtn>
                    <MDBInput
                      placeholder={userData.social1}
                      className={theme === "light" ? "text-dark" : "my-auto"}
                      onChange={handleSocial1Change}
                    />
                  </div>
                )}
              </div>
              <div className="socials-left flex-row d-flex">
                {!isEditMode ? (
                  <div className="socials-2 align-items-center d-flex">
                    <MDBBtn
                      size="lg"
                      floating
                      style={{ backgroundColor: "#ac2bac" }}
                      href="#"
                      className="m-2"
                    >
                      <i class="fas fa-envelope"></i>
                    </MDBBtn>
                    <p
                      className={theme === "light" ? "text-dark" : "my-auto"}
                    >
                      {userData.social2}
                    </p>
                  </div>
                ) : (
                  <div className="socials-2 align-items-center d-flex">
                    <MDBBtn
                      size="lg"
                      floating
                      style={{
                        backgroundColor: "#ac2bac",
                        height: "45px",
                        width: "59px",
                      }}
                      href="#"
                      className="m-2"
                    >
                      <i class="fas fa-envelope"></i>
                    </MDBBtn>
                    <MDBInput
                      placeholder={userData.social2}
                      className={theme === "light" ? "text-dark" : "my-auto"}
                      onChange={handleSocial2Change}
                    />
                  </div>
                )}
              </div>
            </div>
          </MDBAnimation>
        </MDBRow>
      </MDBContainer>
      <MDBContainer fluid className="d-flex flex-column p-3 mt-5">
        <MDBContainer>
          {!isEditMode ? (
            <div className="div"></div>
          ) : (
            <div className="w-25 d-flex pb-2 flex-column">
              <h3>Mine brukerveiledninger</h3>
              <p className="mt-3">Vis brukerveiledninger?</p>
              <MDBCheckbox
                id="controlledCheckbox"
                label="Vis brukerveiledninger?"
                checked={showGuides} // Use showGuides directly
                onChange={handleCheckboxChange}
              />
            </div>
          )}
        </MDBContainer>
        <MDBContainer
          fluid
          className={`${userData.showguides === 1
              ? "d-flex flex-row justify-content-center flex-wrap p-3 mt-5"
              : "visually-hidden"
            }`}
        >
          <h3>Mine brukerveiledninger</h3>
          <div id="admin-modal-wrapper" className="d-flex flex-row flex-wrap justify-content-center w-100">
            {modals.map(
              (modal, index) =>
                (
                  <AdminModal
                    id="admin-modal"
                    modalData={modal}
                    key={index}
                    className="d-flex"
                  />
                ) || "Laster ..."
            )}
          </div>
          <hr className="hr hr-blurry" />
        </MDBContainer>
      </MDBContainer>
    </div>
  );
}

export default ProfilePage;
