import React, { useState, useEffect } from "react";
import CreateModalForm from "./CreateModalForm"; // Import the CreateModalForm component
import EditModalForm from "./EditModalForm"; // Import the EditModalForm component
import AllModals from "./AllModals"; // Import the AllModals component
import "./ModalPanel.css";
import Navbar from "../roaldvgs/navbar/Navbar";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBIcon,
  MDBCol, 
  MDBTypography} from 'mdb-react-ui-kit'
import { useUserPermissions } from "../../context/UserPermissionsContext";


const ModalPanel = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  // Function to handle option selection
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const [theme, setTheme] = useState("light-mode");

  useEffect(() => {
    // Retrieve theme preference from localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      const container = document.body; // or your top-level container
      container.classList.add(savedTheme);
    }
  }, []);

  const { hasPermission } = useUserPermissions();

  const [verticalActive, setVerticalActive] = useState('tab1');

  const handleVerticalClick = (value: string) => {
    if (value === verticalActive) {
      return;
    }

    setVerticalActive(value);
  };


  return (
    <div className="comp_wrapper">
      <Navbar />
      <div className="modalpanel_wrapper">
        <MDBRow style={{ overflow: 'hidden' }}>
          <MDBCol size='3'>
            <MDBTabs pills className='text-center flex-column p-4 modalPill top-50'>
            <MDBTypography tag="h3">Velg side</MDBTypography>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleVerticalClick('tab1')} active={verticalActive === 'tab1'}>
                  <MDBIcon fas icon='cogs' className='me-2' /> Administrer veiledninger
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleVerticalClick('tab2')} active={verticalActive === 'tab2'}>
                  <MDBIcon fas icon="file-signature" className='me-2' /> Ny veiledning
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleVerticalClick('tab3')} active={verticalActive === 'tab3'}>
                  <MDBIcon far icon="clone" className="me-2" /> Kopier veiledninger
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
          </MDBCol>
          <MDBCol size='9' style={{overflow: 'scroll'}}>
            <MDBTabsContent>
              <MDBTabsPane open={verticalActive === 'tab1'}><EditModalForm className="EditModalForm" /></MDBTabsPane>
              <MDBTabsPane open={verticalActive === 'tab2'}><CreateModalForm className="CreateModalForm" /></MDBTabsPane>
              <MDBTabsPane open={verticalActive === 'tab3'}><AllModals className="AllModals" /></MDBTabsPane>
            </MDBTabsContent>
          </MDBCol>
        </MDBRow>
        {/* <div className="NewEditOptions_wrapper">
          <div className="EditCreateMDBBtn_wrapper">
            {hasPermission("ModalCreate") && (
            <MDBBtn className="m-3" onClick={() => handleOptionSelect("new")}>
              Produser Brukerveiledning
            </MDBBtn>
            )}
            {hasPermission("ModalDelete") && (
            <MDBBtn className="m-3" onClick={() => handleOptionSelect("edit")}>
              Administrer Brukerveiledning
            </MDBBtn>
            )}
            {hasPermission("ModalCopy") && (
            <MDBBtn className="m-3" onClick={() => handleOptionSelect("copy")}>
              Kopier Brukerveiledning
            </MDBBtn>
            )}
          </div>
        </div> */}
        
        
        

        {selectedOption === "new" && (
          <CreateModalForm className="CreateModalForm" />
        )}
        {selectedOption === "edit" && (
          <EditModalForm className="EditModalForm" />
        )}
        {selectedOption === "copy" && <AllModals className="AllModals" />}
      </div>
    </div>
  );
};

export default ModalPanel;
